<template>
    <div class="gg_title"><span></span>{{title}}</div>
</template>

<script>
export default {
    components:{},
    props:{
        title:{
            type:String,
            default:'这是标题'
        }
    },
    data(){
        return {
          
        }
    },
    computed:{},
    watch:{},
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{},
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.gg_title{
    height: $titleHeight;
    line-height: $titleHeight;
    background-color: #F3F9FF;
    padding-left: 20px;
    font-weight: bold;
    span{
        width: 10px;
        height: 20px;
        display: inline-block;
        background-color: $titleColor;
        margin-right: 10px;
        position: relative;
        top: 5px;
    }
}
</style>