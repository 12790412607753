<template>
    <div class="interest_tree">
        <el-row>
            <el-col :span="12" class="lf_col">
                <el-input
                    placeholder="输入关键字进行过滤"
                    suffix-icon="el-icon-search"
                    size="small"
                    v-model="filterText">
                    </el-input>
                <div class="lf_tree">
                    <el-tree
                    class="filter-tree"
                    :data="data"
                    show-checkbox
                    node-key="id"
                    :check-on-click-node="true"
                    :props="defaultProps"
                    :check-strictly="true"
                    :filter-node-method="filterNode"
                    @check-change="f_handleCheckChange"
                    ref="tree">
                     <span class="custom-tree-node" slot-scope="{ node, data }">
                        <el-tooltip class="item" effect="dark" :content="node.label" placement="top">
                            <span>{{ node.label }}</span>
                        </el-tooltip>
                     </span>
                    </el-tree>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="rt_content">
                    <div class="clear_all"><p>已选 {{selectedNodesData.length}}</p><p class="clear" @click="f_empty">清空</p></div>
                    <div class="content_choose">
                        <div v-for="item in selectedNodesData" :key="item.id"><p>{{item.label}}</p><p @click="f_del(item)"><i class="el-icon-close"></i></p></div>
                    </div>
                    <footer>
                        <el-row>
                            <el-button  size="medium" @click="f_cancel">取 消</el-button>
                            <el-button type="primary" size="medium" @click="f_sure">保 存</el-button>
                        </el-row>
                    </footer>
                </div>
            </el-col>
        </el-row>
        
    </div>
</template>

<script>
const interestTreeData = require("@/api/dataJson/interestTree.json");
export default {
    components:{},
    props:{},
    data(){
        return {
            filterText: '',
            data: interestTreeData,
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            selectedNodesData:[],//选中的树节点
        }
    },
    computed:{},
    watch:{
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        // 初始化选中项目
        f_initTreeChecked(data){
            this.selectedNodesData = [...data]
            this.$refs.tree.setCheckedKeys(data.map(item=>item.id))
        },
        // 确定
        f_sure(){
            // if(this.selectedNodesData.length==0) return this.$message.warning('请选择受众特征！')
            this.f_cancel(this.selectedNodesData)
        },
        // 清空选中数据
        f_empty(){
            this.selectedNodesData = []
            this.$refs.tree.setCheckedKeys([])
        },
        // 删除树节点
        f_del(item){
            this.f_handleCheckChange(item,false,false)
            this.$refs.tree.setChecked(item,false,false)
        },
        // 选中树
        f_handleCheckChange(data, checked, indeterminate){
            if(checked == true){
                this.selectedNodesData.push(data)
            }else{
                this.selectedNodesData = this.selectedNodesData.filter(item=>item.id!=data.id)
            }
            console.log(data, checked, indeterminate,this.selectedNodesData);
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        f_cancel(data){
            this.$emit('close',data)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
::v-deep.el-tree-node{
    .custom-tree-node{
        width: 328px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        // span{
        //     display: inline-block;
        //     width: 240px;
        //     text-overflow: ellipsis;
        //     white-space: nowrap;
        //     overflow: hidden;
        // }
    }
}
::-webkit-scrollbar {
	width: 7px; /*滚动条宽度*/
	height: 8px; /*滚动条高度*/
}

::-webkit-scrollbar-track {
	border-radius: 3px; /*滚动条的背景区域的圆角*/
	background-color: #f2f2f2; /*滚动条的背景颜色*/
}
::-webkit-scrollbar-thumb {
	background: #999 !important;
	border-radius: 30px !important;
}
.interest_tree{
    height: 600px;
    ::v-deep.el-row{
        height: 100%;
        position: relative;
    }
    .lf_col{
        border-right: 1px solid $bgColorDark;
        height: calc(100%);
        padding: 10px 20px 10px 0;
        ::v-deep.el-input{
            padding-bottom: 10px;
            .el-input__inner{
                width: 99%;
                border-radius: 50px;
            }
            .el-input__suffix{
                right: 15px;
                top: -3px;
            }
        }
        .lf_tree{
            overflow: hidden;
            overflow-y: auto;
            max-height: 530px;
        }
    }
    .rt_content{
        padding: 10px 0px;
        footer{
            position: absolute;
            bottom: 0;
            right: 0;
        }
        .content_choose{
            max-height: 510px;
            overflow: hidden;
            overflow-y: auto;
            padding: 0 10px;
            div{
                display: flex;
                justify-content: space-between;
                padding: 0 10px;
                border: 1px solid transparent;
                border-bottom: 1px solid transparent;
                p{
                    height: 35px;
                    line-height: 35px;
                    
                }
                &:last-child{
                    border-bottom: 1px solid transparent;
                }
                &:hover{
                    border-color: $bgColorBorder;
                    background-color: #F3F9FF;
                    &:last-child{
                        border-bottom-color: $bgColorBorder;
                    }
                    p{
                        i{
                            color: $titleColor; 
                            font-weight: bold;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .clear_all{
            display: flex;
            justify-content: space-between;
            padding: 5px 20px;
            color: $titleColor;
            p.clear{
                cursor: pointer;
            }
            p:first-child{
                font-weight: bold;
                color: #666;
            }
        }
    }
}
</style>