<template>
    <div class="radio_box">
        <el-radio-group v-model="radio" size="small" @change="f_input">
            <el-radio :label="item.key" border v-for="item in adTypeData" :key="item.key"><i :class="item.icon"></i>{{item.value}}</el-radio>
        </el-radio-group>
        <div slot="footer" class="dialog-footer" v-if="isShowBtn">
            <el-button type="primary" size="medium" @click="f_sure">下一步</el-button>
        </div>
    </div>
</template>

<script>
export default {
    components:{},
    props:{
        adTypeData:{
            type:Array,
            default:()=>[]
        },
        value:{
            type:String,
            default:'1'
        },
        isShowBtn:{
            type:Boolean,
            default:false,
        }
    },
    data(){
        return {
          radio:'1'
        }
    },
    computed:{
        newRadio(){
            return this.value
        }
    },
    watch:{
        newRadio:{
            handler(newV){
                this.radio = newV
            },
            immediate:true,
            deep:true
        }
    },
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        // 改变
        f_input(){
            this.$emit('input',this.radio)
        },
        // 确定
        f_sure(){
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.gg_title{
    height: $titleHeight;
    line-height: $titleHeight;
    background-color: #F3F9FF;
    padding-left: 20px;
    font-weight: bold;
    span{
        width: 10px;
        height: 20px;
        display: inline-block;
        background-color: $titleColor;
        margin-right: 10px;
        position: relative;
        top: 5px;
    }
    
}
.radio_box{
    width: 100%;
    .radio_btn{
        position: relative;
        text-align:right;
        margin-top:20px;
    }
    ::v-deep.dialog-footer{
        position: absolute;
        height: 55px;
        width: 410px;
        background: #fff;
        left: 0;
        bottom: -55px;
        padding-top: 10px;
        border-top: 1px solid #E4E4E4;
        text-align: right;
        .el-button{
            margin-right: 35px;
        }
    }
}
::v-deep.el-radio-group{
    width: 100%;
    .el-radio{
        width: 43.5%;
        height: 76px;
        line-height: 60px;
        background-color: $bgColor;
        border-radius: 5px;
        border-color: $bgColor;
        .el-radio__input{
            display: none;
        }
        .el-radio__label{
            font-size: 14px;
            i{
                font-size: 30px;
                margin-right: 10px;
                position: relative;
                top: 5px;
            }
        }
        &.is-checked{
            background-color: $bgColorImg;
            border-color:$bgColorBorder;
        }
    }
}

</style>