<template>
    <div class="interest_audience">
        <!-- 受众群体 -->
        <div class="interest_search" @click="f_openAdGroup"><i class="el-icon-search"></i>选择受众群体</div>
        <div class="content_list" v-if="listData.length>0">
            <div class="box" v-for="item in listData" :key="item.id">
                <p>{{item.label}}</p>
                <p><span>>100亿</span> <i class="el-icon-close" @click="f_del(item.id)"></i></p>
            </div>
        </div>
        <!-- 受众群体选择 -->
        <my-dialog :myDialogObj="adgroupVisible">
            <interest-tree @close="f_close" ref="interestTree"></interest-tree>
        </my-dialog>
    </div>
</template>

<script>
const interestTreeData = require("@/api/dataJson/interestTree.json");
import interestTree from './interestTree.vue'
import MyDialog from '../../common/myDialog/index.vue'
export default {
    components:{MyDialog,interestTree},
    props:{
        value:{
            type:Array,
            default:()=>[]
        },
    },
    data(){
        return {
          interestTreeData:interestTreeData,
          listData:[],//选择后的数据列表
          adgroupVisible:{
                title:'选择受众',
                dialogVisible:false,
                width:'800px',
                top:'15vh',
                class:'noHeader'
        },
        }
    },
    computed:{
        thirdData(){
            return this.value
        },
        complanation(){
            console.log(this.interestTreeData,"===========")
            let data = this.f_complanationTree(this.interestTreeData,[])
            console.log(data)
            return data
        },
    },
    watch:{
        thirdData:{
            handler(newV){
                let data = [...newV]
                this.f_initData(data.filter(item=>item.type=='USER_INTEREST'))
            },
            immediate:true,
            deep:true
        }
    },
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        // 把树形数据全部平铺到一级
        f_complanationTree(data,arr){
            data.reduce((prev,cur)=>{
                if(cur.children&&cur.children.length>0) this.f_complanationTree(cur.children,arr)
                return arr.push(cur)
            },arr)
            return arr
        },
        // 初始化listData
        f_initData(data){
            console.log(data,"兴趣词")
            if(data.length>0){
                let array = data.map(item=>item.criterion_id),newData = []
                // listData转化为树的格式
                this.complanation.map(item=>{
                    if(array.includes(parseInt(item.id))||array.includes(item.id)) newData.push(item)
                })
                this.listData = [...newData]
                console.log(this.listData,"兴趣词展示--")
            }else{
                this.listData = []
            }
        },
        // 打开弹窗
        f_openAdGroup(){
            this.adgroupVisible.dialogVisible = true
            this.$nextTick(()=>{
                this.$refs.interestTree.f_initTreeChecked([...this.listData])
            })
        },
        // 删除数据
        f_del(id){
            let k = -1
            this.listData.map((item,index)=>{
                if(item.id == id) k = index
            })
            if(k>-1) {
                this.listData.splice(k,1)
                this.f_input()
            }
        },
        // 关闭弹窗
        f_close(data){
            this.adgroupVisible.dialogVisible = false
            console.log(data)
            if(Array.isArray(data)){
                this.listData = [...data]
                this.f_input()
            }
        },
        // 处理数据格式传给大表单
        f_input(){
            let array = this.value.filter(item=>item.type!='USER_INTEREST')
            // 树转化为传给后台的格式
            let newD = []
            if(this.listData.length>0) this.listData.map(item=>{
                let obj = {}
                obj.type = 'USER_INTEREST'
                obj.criterion_id = item.id
                newD.push(obj)
            })
            console.log(newD,'兴趣词改变了')
            this.$emit('input',[...newD,...array])
        },
    },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	width: 7px; /*滚动条宽度*/
	height: 8px; /*滚动条高度*/
}

::-webkit-scrollbar-track {
	border-radius: 3px; /*滚动条的背景区域的圆角*/
	background-color: #f2f2f2; /*滚动条的背景颜色*/
}
::-webkit-scrollbar-thumb {
	background: #999 !important;
	border-radius: 30px !important;
}
@import "~@/styles/variables.scss";
.interest_audience{
    .interest_search{
        width: 100%;
        height: 32px;
        line-height: 32px;
        border-radius: 5px;
        border: 1px solid $bgColorDark;
        cursor: pointer;
        color: $subTitleColor;
        i{
            margin: 0 10px;
        }
    }
    .content_list{
        border: 1px solid $bgColorDark;
        max-height: 360px;
        overflow: hidden;
        overflow-y: scroll;
        margin: 10px 0;
        border-radius: 5px;
        padding: 0 10px;
        .box{
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            p{
                height: 38px;
                line-height: 38px;
                i{
                    font-weight: bold;
                    color: #666;
                    font-size: 12px;
                    cursor: pointer;
                    &:hover{
                        color: $titleColor;
                    }
                }
                span{
                    font-size: 12px;
                    color: $subTitleColor;
                    margin-right: 20px;
                }
            }
        }
    }
}
</style>