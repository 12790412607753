<template>
    <div class="promote_link">
        <!-- 推广链接 -->
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
            <el-form-item label="商品ID" prop="saleId">
                <div class="cloneSales">
                    <el-input v-model="ruleForm.saleId" placeholder="请输入商品ID"></el-input>
                    <el-checkbox v-model="ruleForm.clone">克隆商品</el-checkbox>
                </div>
            </el-form-item>
            <el-form-item label="站点ID" prop="collId" class="site" >
                <el-select v-model="ruleForm.collId" filterable :remote="true" reserve-keyword placeholder="请输入站点ID" :remote-method="f_remoteMethod" value-key="id">
                    <el-option v-for="item in sitesData" :key="item.id" :label="item.domain+'#'+item.id+'('+item.saleName+')'" :value="item" >
                        <span>{{item.domain}}#{{item.id}}({{item.saleName}})</span>
                    </el-option>
                    <div slot="empty" class="empty" v-loading="loading">
                        <i class="iconfont icon-wushuju"></i>
                        <p>暂无数据</p>
                    </div>
                </el-select>
            </el-form-item>
            <el-row class="sumit">
                <el-button type="primary" @click="f_onSubmit">生成推广链接</el-button>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {searchSites} from '@/api/google/adManagement.js'
import {addSaleToSite} from '@/api/google/ceateAdGg.js'
export default {
    components:{},
    props:{},
    data(){
        return {
          loading:false,
          ruleForm:{},
          rules:{
              collId: [
                    { required: true, message: '请选择输入站点ID', trigger: ['change','blur'] }
              ],
              saleId: [
                    { required: true, message: '请选择输入商品ID', trigger: ['change','blur'] }
              ],
          },
          sitesData:[],
        }
    },
    computed:{},
    watch:{},
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        // 生成推广链接
         f_onSubmit(){
          this.$refs.ruleForm.validate(async (valid) => {
            if (!valid) return false;
            this.$showLoading()
            // {...this.ruleForm,collId:this.ruleForm.collId.id}
            let res = await addSaleToSite({"saleId":this.ruleForm.saleId,"collId":this.ruleForm.collId.id})
            this.$hideLoading();
            if(res.code == 0){
                console.log(res)
                this.$emit('getLandURL',{landing_url:res.data.promotionLink,sale_id:this.ruleForm.saleId,site_id:this.ruleForm.collId.id})
            }
          });
        },
        // 可用的推广的站点
        f_remoteMethod(query) {
            this.sitesData = [];
            this.loading = true;
            searchSites({keyword:query,gkshop:true}).then(res=>{
                // console.log(res);
                this.loading = false;
                if(res.code == 0 && res.data.length){
                    this.sitesData = res.data;
                }else{
                    this.sitesData = [];
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
$linkHeight:225px;
.cloneSales{
    display: flex;
    justify-content: space-around;
    ::v-deep.el-input{
        width: $linkHeight;
    }
    ::v-deep.el-checkbox{
        margin-left: 3px;
    }
}
.site{
    .el-select{
        width: $linkHeight;
    }
}
.sumit{
    text-align:right;
    margin-bottom:10px;
    margin-right: 10px;
}
</style>