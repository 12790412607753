<template>
    <div class="found_ads">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
            <el-form-item label="图片" prop="image">
                <div class="preview_gg">
                    <div class="add" @click="f_addImg(['1.00','1.91','0.80','0.99','1.01','0.81','0.79'],20,'image')" v-if="allImageListData.length<20">
                        <p><i class="el-icon-plus"></i></p>
                        <p>添加图片</p>
                    </div>
                    <div v-for="item in allImageListData" class="img_box">
                        <img :src="item.image_url" alt="">
                        <i class="el-icon-close" @click="f_delImage(item,'image')"></i>
                    </div>
                </div>
                <div class="tips">注：最多上传 <span>20</span> 张，支持 <span>1:1，1.91:1，4:5</span> ，3种尺寸至少各 <span>1</span> 张</div>
            </el-form-item>
            <el-form-item label="徽标" prop="logo">
                <div class="preview_gg">
                    <div class="add" @click="f_addImg(['1.00','0.99','1.01'],5,'logo')" v-if="allLogoListData.length<5">
                        <p><i class="el-icon-plus"></i></p>
                        <p>添加图片</p>
                    </div>
                    <div v-for="item in allLogoListData" class="img_box">
                        <img :src="item.image_url" alt="">
                        <i class="el-icon-close" @click="f_delImage(item,'logo')"></i>
                    </div>
                </div>
                <div class="tips">注：最多上传 <span>5</span> 张，支持 <span>1:1</span> 尺寸</div>
            </el-form-item>
            <el-form-item label="号召语" prop="call_to_action_text">
                <div class="call">
                    <!-- <el-input v-model="ruleForm.call_to_action_text" style="width:100%;" placeholder="请输入号召语" maxlength="999999" show-word-limit v-limitWords="15" @input="$maxLength('call_to_action_text',15)" @blur="f_changeCallToAction(ruleForm.call_to_action_text)"></el-input> -->
                    <el-autocomplete class="inline-input" :fetch-suggestions="f_querySearch" value-key="shopNow" :value="ruleForm.call_to_action_text" style="width:100%;" placeholder="请输入号召语" :maxlength="99999" show-word-limit v-limitWords="15" @input="f_inputCallToAction" @select="f_changeCallToAction" @change="f_inputCallToAction">
                        <template slot-scope="{ item }">
                            <div class="flex">
                                <p>{{ item.shopNow }}</p>
                                <p>{{ item.name_zhcn }}</p>
                            </div>
                        </template>
                    </el-autocomplete>
                    <!-- <el-radio-group v-model="ruleForm.radio">
                        <el-radio-button label="自动"></el-radio-button>
                        <el-radio-button label="手动"></el-radio-button>
                    </el-radio-group> -->
                    <!-- <el-select v-model="ruleForm.call_to_action_text" placeholder="请选择" @change="f_inputFound" >
                        <el-option
                        v-for="item in callToActionData"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select> -->
                    <!-- <el-select
                        v-model="ruleForm.call_to_action_text"
                        multiple
                        filterable
                        @change="f_inputFound"
                        allow-create
                        default-first-option
                        placeholder="请选择文章标签">
                        <el-option
                        v-for="item in callToActionData"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select> -->
                </div>
            </el-form-item>
            <el-form-item label="商家名称" prop="business_name">
                <el-input v-model="ruleForm.business_name" maxlength="999999" show-word-limit v-limitWords="25" @input="$maxLength('business_name',25)" @change="f_inputFound"  :class="[isCludeArabic?'rtl':'']"></el-input>
            </el-form-item>
            <div class="noStar">
                <el-form-item
                    v-for="(domain, index) in ruleForm.headlines"
                    :label="index==0?'标题':'    '"
                    :key="domain.key"
                    :prop="'headlines.' + index + '.text'"
                    :rules="{
                    required: true, message: '标题不能为空', trigger: ['change','blur']
                    }"
                >
                <div class="more">
                    <el-input v-model="domain.text" maxlength="999999" show-word-limit v-limitWords="40" @input="$maxLength('text',40,index,'headlines')" @change="f_inputFound" :class="[isCludeArabic?'rtl':'']"></el-input>
                    <el-row><el-button @click.prevent="addDomain" type="text" icon="el-icon-plus"></el-button><el-button @click.prevent="removeDomain(domain)" type="text" icon="el-icon-delete"></el-button></el-row>
                </div>
                </el-form-item>
            </div>
            <div class="noStar">
                <el-form-item
                    v-for="(domain, index) in ruleForm.descriptions"
                    :label="index==0?'描述':'    '"
                    :key="domain.key"
                    :prop="'descriptions.' + index + '.text'"
                    :rules="{
                    required: true, message: '描述不能为空', trigger: ['change','blur']
                    }"
                >
                <div class="more">
                    <el-input v-model="domain.text" maxlength="999999" show-word-limit v-limitWords="90" @input="$maxLength('text',90,index,'descriptions')" type="textarea" @change="f_inputFound"  :class="[isCludeArabic?'rtl':'']"></el-input>
                    <el-row><el-button @click.prevent="addDomain('text')" type="text" icon="el-icon-plus"></el-button><el-button @click.prevent="removeDomain(domain,'text')" type="text" icon="el-icon-delete"></el-button></el-row>
                </div>
                </el-form-item>
            </div>
        </el-form>
        <!-- 文件上传 -->
        <my-drawer :drawerObj="myDrawerObj">
            <choose-materials :accountId="accountId" :checkIds="checkIds" :limit="currentLimit" :limitSize="limitSize" :typeMaterials="typeMaterials" :limitNum="limitNum" @close="f_close" ref="chooseMaterialsR"></choose-materials>
        </my-drawer>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import myDrawer from '../common/drawer/index.vue'
import chooseMaterials from './chooseMaterials.vue'
export default {
    components:{myDrawer,chooseMaterials},
    props:{
        value:{
            type:Object,
            default:()=>{}
        },
        isPass:{
            type:Boolean,
            default:false
        },
        defaultCallToActions:{
            type:Object,
            default:()=>{}
        },
        accountId: {
            type: [String, Array],
            default: "",
        },
        isCludeArabic:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
          typeMaterials:'',//素材还是徽标
          checkIds:[],
          myDrawerObj:{
              title:'选择要在广告中使用的 20 张图片',
              drawer:false,
              size:'80%'
          },
          currentLimit:[],
          ruleForm:{
              call_to_action_text:'',
              image:'',
              logo:'',
              square_marketing_images:[],
              marketing_images:[],
              portrait_marketing_images:[],
              logo_images:[],
              headlines:[{text:''}],
              descriptions:[{text:''}],
          },
          rules:{
              image:[
                    { required: true, message: '请选择要上传的创意', trigger: ['change','blur'] }
              ],
              logo:[
                    { required: true, message: '请选择要上传的创意', trigger: ['change','blur'] }
              ],
             call_to_action_text:[
                    { required: true, message: '号召语必须', trigger: ['change','blur'] }
              ],
              business_name:[
                    { required: false, message: '请输入商家名称', trigger: ['blur'] }
              ],
          },
          limitSize:[],//限制上传的图片比例尺寸
          limitNum:0,//限制选择的数量
        }
    },
    computed:{
        ...mapState({
            constDataGoogle: state => state.initData.constDataGoogle,
        }),
        
        thirdData(){
            return this.value
        },
        allImageListData(){
            return [...this.ruleForm.square_marketing_images,...this.ruleForm.marketing_images,...this.ruleForm.portrait_marketing_images]
        },
        allLogoListData(){
            return [...this.ruleForm.logo_images]
        },
    },
    watch:{
        isPass:{
            handler(newV){
                this.f_submit()
            },
        },
        thirdData:{
            handler(newV){
                this.f_initData(newV)
            },
            immediate:true,
            deep:true
        },
        defaultCallToActions:{
            handler(newV){
                this.f_initCallToAction(newV)
            }
        }
    },
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        // 显示建议
        f_querySearch(queryString, cb) {
            var restaurants = this.constDataGoogle.defaulcallToActionData;
            console.log(restaurants,";;;")
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return restaurant.ShopNow?(restaurant.ShopNow.toLowerCase().indexOf(queryString.toLowerCase()) === 0):[];
            };
        },
        // 初始化行动号召力
        f_initCallToAction(newV){
            this.$nextTick(()=>{
                this.$set(this.ruleForm,'call_to_action_text',newV.shopNow)
            })
        },
        // 输入框
        f_inputCallToAction(val){
            this.ruleForm.call_to_action_text = val
            this.f_common()
        },
        // 行动号召力
       f_changeCallToAction(item){
            this.ruleForm.call_to_action_text = item.shopNow
            this.f_common()
        },
        async f_common(){
            await this.$nextTick()
            this.$maxLength('call_to_action_text',15)
            this.f_inputFound()
        },
        // 校验是否通过
        f_submit(){
            this.$refs.ruleForm.clearValidate();
            this.$refs.ruleForm.validate((valid) => {
                if (!valid) {
                    this.$firstInputFocus()
                    this.$store.commit('initData/setGoogleValid',false)
                    return false;
                }
                this.$store.commit('initData/setGoogleValid',true)

            });
        },
        // 判断比例是否合适
        checkSize(limitSize,size){
            return limitSize.includes(size.toFixed(2))
        },
        // 改变值了
        f_inputFound(){
            this.$emit('input',this.ruleForm)
        },
        // 删除素材
        f_delImage(data,type){
            let k = -1
            if(type == 'image'){
                this.ruleForm.square_marketing_images.map((item,i)=>{
                    if(item.image_url == data.image_url) k = i
                })
                if(k!=-1) this.ruleForm.square_marketing_images.splice(k,1)
                else {
                    this.ruleForm.marketing_images.map((m,i)=>{
                        if(m.image_url == data.image_url) k = i
                    })
                    if(k!=-1) this.ruleForm.marketing_images.splice(k,1)
                    else{
                        this.ruleForm.portrait_marketing_images.map((p,i)=>{
                            if(p.image_url == data.image_url) k = i
                        })
                        if(k!=-1) this.ruleForm.portrait_marketing_images.splice(k,1)
                    }
                }
            }else{
                this.ruleForm.logo_images.map((l,i)=>{
                    if(l.image_url == data.image_url) this.ruleForm.logo_images.splice(i,1)
                })
            }
            this.f_inputFound()
        },
        f_initData(data){
            console.log(data,"发现广告")
            // 处理图片和徽标的默认值
            if(data.marketing_images.length>0||data.portrait_marketing_images.length>0||data.square_marketing_images.length>0){
                this.$set(data,'image','image')
                this.$refs.ruleForm.clearValidate('image');//假校验
            }else{
                this.$set(data,'image','')
            }
            if(data.logo_images.length>0){
                this.$set(data,'logo','logo')
                this.$refs.ruleForm.clearValidate('logo');//假校验
            }else{
                this.$set(data,'logo','')
            }
            this.ruleForm = {...data}
        },
        // 关闭窗口
        f_close(data,type){
            this.myDrawerObj.drawer = false
            if(data&&data.length>0){
                // 处理徽标
                if(type == 'logo'){
                  let logo_images = []
                  data.map(item=>{ 
                      let obj = {asset:'',image_url:''}
                      obj.image_url = item.image_asset.full_size.url
                      obj.asset = item.resource_name
                      logo_images.push(obj)
                  })
                  this.ruleForm.logo_images = [...logo_images]
                  this.$set(this.ruleForm,'logo','logo')
                  this.$refs.ruleForm.clearValidate('logo');//假校验
                }else if(type == 'image'){
                // 处理素材
                    let marketing_images = [],square_marketing_images = [],portrait_marketing_images = []
                    data.map(item=>{
                        let obj = {asset:'',image_url:''},p = item.image_asset.full_size.width_pixels/item.image_asset.full_size.height_pixels
                            obj.image_url = item.image_asset.full_size.url
                            obj.asset = item.resource_name
                        if(this.checkSize(['1.91'],p)) marketing_images.push(obj)
                        else if(this.checkSize(['0.80','0.81','0.79'],p)) portrait_marketing_images.push(obj)
                        else square_marketing_images.push(obj)
                    })
                    this.ruleForm.marketing_images = [...marketing_images]
                    this.ruleForm.square_marketing_images = [...square_marketing_images]
                    this.ruleForm.portrait_marketing_images = [...portrait_marketing_images]
                    this.$set(this.ruleForm,'image','image')
                    this.$refs.ruleForm.clearValidate('image');//假校验
                }
                this.f_inputFound()
            }
        },
        f_addImg(data,num,type){
            console.log(data,num,type,"限制的比例")
            this.typeMaterials = type
            this.limitSize = [...data]
            this.limitNum = num
            this.myDrawerObj.title = `选择要在广告中使用的 ${num} 张图片`
            this.myDrawerObj.drawer = true
            let {marketing_images,square_marketing_images,portrait_marketing_images,logo_images} = {...this.ruleForm}
            let array = [],ids = []
            if(type == 'image'){
                array = [...marketing_images,...square_marketing_images,...portrait_marketing_images]
            }else{
                array = [...logo_images]
            }
            array.map(item=>ids.push(item.image_url))
            this.checkIds = ids
            this.currentLimit = data
        },
        // 添加删除多项
        removeDomain(item,type) {
            let domains = 'headlines'
            if(type=='text') domains = 'descriptions'
            if(this.ruleForm[domains].length<=1) return this.$message.warning("已经是最后一条了！")
            var index = this.ruleForm[domains].indexOf(item)
            if (index !== -1) {
                this.ruleForm[domains].splice(index, 1)
                this.f_inputFound()
            }
        },
        addDomain(type) {
            let domains = 'headlines'
            if(type=='text') domains = 'descriptions'
            if (this.ruleForm['headlines'].length >= 5&&type!='text') {
              this.$message.warning("最多5个标题！");
              return;
            }
            if (this.ruleForm['descriptions'].length >= 5&&type=='text') {
              this.$message.warning("最多5个描述！");
              return;
            }
            this.ruleForm[domains].push({
                text: '',
                key: Date.now()
            });
            this.f_inputFound()
        }
    },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.flex{
    display: flex;
    justify-content: space-between;
}
::v-deep.rtl{
    direction: rtl;
    padding-right: 40px;
}
.found_ads{
    $addHeight:80px;
    .preview_gg{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-left: -10px;
        .img_box{
            margin-left: 10px;
            position: relative;
            img{
                height: 80px;
                width: auto;
                border-radius: 2px;
                border: 1px solid $bgColorBorder;
            }
            i{
                font-size: 14px;
                background-color: red;
                color: #fff;
                border-radius: 50%;
                font-weight: bold;
                position: absolute;
                top: 3px;
                right: 3px;
                cursor: pointer;
            }
        }
    }
    .add{
        background-color: $bgColorImg;
        border: 1px dashed $bgColorBorder;
        width: $addHeight;
        height: $addHeight;
        text-align: center;
        padding-top: 15px;
        cursor: pointer;
        margin-left: 10px;
        p{
            line-height: 20px;
            color: $titleColor;
            font-size: 12px;
            i{
                font-weight: bold;
            }
        }
    }
    .tips{
        font-size: 12px;
        color: $subTitleColor;
        margin-top: 5px;
        span{
            color: $titleColor;
        }
    }
    .noStar{
        ::v-deep.el-form-item{
            &:not(:first-child){
                .el-form-item__label{
                    &::before{
                        display: none;
                    }
                }
            }
        }
    }
    ::v-deep.call{
        display: flex;
        justify-content: baseline;
        // .el-select{
        //     margin-left: 20px;
        // }
    }
    @mixin flexStart {
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }
    .more{
        @include flexStart;
        .el-row{
            @include flexStart;
            margin-left: 5px;
        }
    }
}
</style>